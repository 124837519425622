body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1rem;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.shadow {
    -webkit-box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 2px 2px 4px 0px rgba(0, 0, 0, 0.75);
}

.content-area {
    padding-top: 8px;
    padding-bottom: 32px;
}

.tappable {
    cursor: pointer;
}

.content-background {
    background-color: white;
}

.nav-link {
    color: white !important;
}

.hoauk-secondary-color {
    background-color: #fbea92;
}

a {
    color: black;
    font-style: normal;
    text-decoration: none;
}

address {
    font-style: normal;
}

ul li:not(:last-child) {
    margin-bottom: 0.5rem;
}

p {
    font-size: 1rem;
}

div {
    font-size: 1rem;
}
